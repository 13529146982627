import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_create_account",
  ref: "createAccountModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body scroll-y m-5" }
const _hoisted_8 = {
  ref: "createAccountRef",
  class: "stepper stepper-links d-flex flex-column",
  id: "kt_create_account_stepper"
}
const _hoisted_9 = {
  class: "current",
  "data-kt-stepper-element": "content"
}
const _hoisted_10 = { class: "w-100" }
const _hoisted_11 = { class: "fv-row" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-lg-6" }
const _hoisted_14 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10",
  for: "kt_create_account_form_account_type_personal"
}
const _hoisted_15 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_16 = { class: "col-lg-6" }
const _hoisted_17 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center",
  for: "kt_create_account_form_account_type_corporate"
}
const _hoisted_18 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_19 = { "data-kt-stepper-element": "content" }
const _hoisted_20 = { class: "w-100" }
const _hoisted_21 = { class: "mb-10 fv-row" }
const _hoisted_22 = {
  class: "row mb-2",
  "data-kt-buttons": "true"
}
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "mb-10 fv-row" }
const _hoisted_28 = { class: "mb-0 fv-row" }
const _hoisted_29 = { class: "mb-0" }
const _hoisted_30 = { class: "d-flex flex-stack mb-5 cursor-pointer" }
const _hoisted_31 = { class: "d-flex align-items-center me-2" }
const _hoisted_32 = { class: "symbol symbol-50px me-6" }
const _hoisted_33 = { class: "symbol-label" }
const _hoisted_34 = { class: "svg-icon svg-icon-1 svg-icon-gray-600" }
const _hoisted_35 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_36 = { class: "d-flex flex-stack mb-5 cursor-pointer" }
const _hoisted_37 = { class: "d-flex align-items-center me-2" }
const _hoisted_38 = { class: "symbol symbol-50px me-6" }
const _hoisted_39 = { class: "symbol-label" }
const _hoisted_40 = { class: "svg-icon svg-icon-1 svg-icon-gray-600" }
const _hoisted_41 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_42 = { class: "d-flex flex-stack mb-0 cursor-pointer" }
const _hoisted_43 = { class: "d-flex align-items-center me-2" }
const _hoisted_44 = { class: "symbol symbol-50px me-6" }
const _hoisted_45 = { class: "symbol-label" }
const _hoisted_46 = { class: "svg-icon svg-icon-1 svg-icon-gray-600" }
const _hoisted_47 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_48 = { "data-kt-stepper-element": "content" }
const _hoisted_49 = { class: "w-100" }
const _hoisted_50 = { class: "fv-row mb-10" }
const _hoisted_51 = { class: "fv-row mb-10" }
const _hoisted_52 = { class: "fv-row mb-10" }
const _hoisted_53 = { class: "fv-row mb-10" }
const _hoisted_54 = { class: "fv-row mb-0" }
const _hoisted_55 = { "data-kt-stepper-element": "content" }
const _hoisted_56 = { class: "w-100" }
const _hoisted_57 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_58 = { class: "fv-plugins-message-container" }
const _hoisted_59 = { class: "fv-help-block" }
const _hoisted_60 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_61 = { class: "position-relative" }
const _hoisted_62 = { class: "fv-plugins-message-container" }
const _hoisted_63 = { class: "fv-help-block" }
const _hoisted_64 = { class: "row mb-10" }
const _hoisted_65 = { class: "col-md-8 fv-row" }
const _hoisted_66 = { class: "row fv-row" }
const _hoisted_67 = { class: "col-6" }
const _hoisted_68 = ["label", "value"]
const _hoisted_69 = { class: "fv-plugins-message-container" }
const _hoisted_70 = { class: "fv-help-block" }
const _hoisted_71 = { class: "col-6" }
const _hoisted_72 = ["label", "value"]
const _hoisted_73 = { class: "fv-plugins-message-container" }
const _hoisted_74 = { class: "fv-help-block" }
const _hoisted_75 = { class: "col-md-4 fv-row" }
const _hoisted_76 = { class: "position-relative" }
const _hoisted_77 = { class: "position-absolute translate-middle-y top-50 end-0 me-3" }
const _hoisted_78 = { class: "svg-icon svg-icon-2hx" }
const _hoisted_79 = { class: "fv-plugins-message-container" }
const _hoisted_80 = { class: "fv-help-block" }
const _hoisted_81 = { "data-kt-stepper-element": "content" }
const _hoisted_82 = { class: "w-100" }
const _hoisted_83 = { class: "pb-8 pb-lg-10" }
const _hoisted_84 = { class: "text-gray-400 fw-bold fs-6" }
const _hoisted_85 = { class: "mb-0" }
const _hoisted_86 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed p-6" }
const _hoisted_87 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }
const _hoisted_88 = { class: "d-flex flex-stack pt-15" }
const _hoisted_89 = { class: "me-2" }
const _hoisted_90 = { class: "svg-icon svg-icon-3 me-1" }
const _hoisted_91 = { class: "indicator-label" }
const _hoisted_92 = { class: "svg-icon svg-icon-3 ms-2 me-0" }
const _hoisted_93 = {
  key: 1,
  type: "submit",
  class: "btn btn-lg btn-primary"
}
const _hoisted_94 = { class: "svg-icon svg-icon-3 ms-1 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("h2", null, "Create Account", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[55] || (_cache[55] = _createStaticVNode("<div class=\"stepper-nav py-5\"><div class=\"stepper-item current\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Account Type</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Account Info</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Business Details</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Billing Details</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Completed</h3></div></div>", 1)),
            _createElementVNode("form", {
              class: "mx-auto mw-600px w-100 py-10",
              novalidate: "novalidate",
              id: "kt_create_account_form",
              onSubmit: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)))
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"pb-10 pb-lg-15\"><h2 class=\"fw-bolder d-flex align-items-center text-dark\"> Choose Account Type <i class=\"fas fa-exclamation-circle ms-2 fs-7\" data-bs-toggle=\"tooltip\" title=\"Billing is issued based on your selected account type\"></i></h2><div class=\"text-gray-400 fw-bold fs-6\"> If you need more info, please check out <a href=\"#\" class=\"link-primary fw-bolder\">Help Page</a>. </div></div>", 1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          name: "accountType",
                          value: "personal",
                          checked: "checked",
                          id: "kt_create_account_form_account_type_personal",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.accountType) = $event))
                        }, null, 512), [
                          [_vModelRadio, _ctx.formData.accountType]
                        ]),
                        _createElementVNode("label", _hoisted_14, [
                          _createElementVNode("span", _hoisted_15, [
                            _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com005.svg" })
                          ]),
                          _cache[13] || (_cache[13] = _createElementVNode("span", { class: "d-block fw-bold text-start" }, [
                            _createElementVNode("span", { class: "text-dark fw-bolder d-block fs-4 mb-2" }, " Personal Account "),
                            _createElementVNode("span", { class: "text-gray-400 fw-bold fs-6" }, "If you need more info, please check it out")
                          ], -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          name: "accountType",
                          value: "corporate",
                          id: "kt_create_account_form_account_type_corporate",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.accountType) = $event))
                        }, null, 512), [
                          [_vModelRadio, _ctx.formData.accountType]
                        ]),
                        _createElementVNode("label", _hoisted_17, [
                          _createElementVNode("span", _hoisted_18, [
                            _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin006.svg" })
                          ]),
                          _cache[14] || (_cache[14] = _createElementVNode("span", { class: "d-block fw-bold text-start" }, [
                            _createElementVNode("span", { class: "text-dark fw-bolder d-block fs-4 mb-2" }, "Corporate Account"),
                            _createElementVNode("span", { class: "text-gray-400 fw-bold fs-6" }, "Create corporate account to mane users")
                          ], -1))
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _cache[27] || (_cache[27] = _createElementVNode("div", { class: "pb-10 pb-lg-15" }, [
                    _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Account Info"),
                    _createElementVNode("div", { class: "text-gray-400 fw-bold fs-6" }, [
                      _createTextVNode(" If you need more info, please check out "),
                      _createElementVNode("a", {
                        href: "#",
                        class: "link-primary fw-bolder"
                      }, "Help Page"),
                      _createTextVNode(". ")
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-flex align-items-center form-label mb-3" }, [
                      _createTextVNode(" Specify Team Size "),
                      _createElementVNode("i", {
                        class: "fas fa-exclamation-circle ms-2 fs-7",
                        "data-bs-toggle": "tooltip",
                        title: "Provide your team size to help us setup your billing"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          checked: "checked",
                          name: "teamSize",
                          value: "1-1",
                          id: "kt_one_one_select",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.teamSize) = $event))
                        }, null, 512), [
                          [_vModelRadio, _ctx.formData.teamSize]
                        ]),
                        _cache[16] || (_cache[16] = _createElementVNode("label", {
                          class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4",
                          for: "kt_one_one_select"
                        }, [
                          _createElementVNode("span", { class: "fw-bolder fs-3" }, "1-1")
                        ], -1))
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          name: "teamSize",
                          value: "2-10",
                          id: "kt_two_ten_select",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.teamSize) = $event))
                        }, null, 512), [
                          [_vModelRadio, _ctx.formData.teamSize]
                        ]),
                        _cache[17] || (_cache[17] = _createElementVNode("label", {
                          class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4",
                          for: "kt_two_ten_select"
                        }, [
                          _createElementVNode("span", { class: "fw-bolder fs-3" }, "2-10")
                        ], -1))
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          name: "teamSize",
                          value: "10-50",
                          id: "kt_ten_fifty_select",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.teamSize) = $event))
                        }, null, 512), [
                          [_vModelRadio, _ctx.formData.teamSize]
                        ]),
                        _cache[18] || (_cache[18] = _createElementVNode("label", {
                          class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4",
                          for: "kt_ten_fifty_select"
                        }, [
                          _createElementVNode("span", { class: "fw-bolder fs-3" }, "10-50")
                        ], -1))
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          name: "teamSize",
                          value: "50+",
                          id: "kt_fifty_select",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.teamSize) = $event))
                        }, null, 512), [
                          [_vModelRadio, _ctx.formData.teamSize]
                        ]),
                        _cache[19] || (_cache[19] = _createElementVNode("label", {
                          class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4",
                          for: "kt_fifty_select"
                        }, [
                          _createElementVNode("span", { class: "fw-bolder fs-3" }, "50+")
                        ], -1))
                      ])
                    ]),
                    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "form-text" }, " Customers will see this shortened version of your statement descriptor ", -1))
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label mb-3" }, "Team Account Name", -1)),
                    _createVNode(_component_Field, {
                      type: "text",
                      class: "form-control form-control-lg form-control-solid",
                      name: "teamAccountName",
                      placeholder: "",
                      value: ""
                    }),
                    _createVNode(_component_ErrorMessage, {
                      class: "fv-plugins-message-container invalid-feedback",
                      name: "teamAccountName"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _cache[26] || (_cache[26] = _createElementVNode("label", { class: "d-flex align-items-center form-label mb-5" }, [
                      _createTextVNode(" Select Account Plan "),
                      _createElementVNode("i", {
                        class: "fas fa-exclamation-circle ms-2 fs-7",
                        "data-bs-toggle": "tooltip",
                        title: "Monthly billing will be based on your account plan"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("label", _hoisted_30, [
                        _createElementVNode("span", _hoisted_31, [
                          _createElementVNode("span", _hoisted_32, [
                            _createElementVNode("span", _hoisted_33, [
                              _createElementVNode("span", _hoisted_34, [
                                _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin001.svg" })
                              ])
                            ])
                          ]),
                          _cache[23] || (_cache[23] = _createElementVNode("span", { class: "d-flex flex-column" }, [
                            _createElementVNode("span", { class: "fw-bolder text-gray-800 text-hover-primary fs-5" }, "Company Account"),
                            _createElementVNode("span", { class: "fs-6 fw-bold text-gray-400" }, "Use images to enhance your post flow")
                          ], -1))
                        ]),
                        _createElementVNode("span", _hoisted_35, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            name: "accountPlan",
                            value: "1",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.accountPlan) = $event))
                          }, null, 512), [
                            [_vModelRadio, _ctx.formData.accountPlan]
                          ])
                        ])
                      ]),
                      _createElementVNode("label", _hoisted_36, [
                        _createElementVNode("span", _hoisted_37, [
                          _createElementVNode("span", _hoisted_38, [
                            _createElementVNode("span", _hoisted_39, [
                              _createElementVNode("span", _hoisted_40, [
                                _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra006.svg" })
                              ])
                            ])
                          ]),
                          _cache[24] || (_cache[24] = _createElementVNode("span", { class: "d-flex flex-column" }, [
                            _createElementVNode("span", { class: "fw-bolder text-gray-800 text-hover-primary fs-5" }, "Developer Account"),
                            _createElementVNode("span", { class: "fs-6 fw-bold text-gray-400" }, "Use images to your post time")
                          ], -1))
                        ]),
                        _createElementVNode("span", _hoisted_41, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            checked: "",
                            name: "accountPlan",
                            value: "2",
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.accountPlan) = $event))
                          }, null, 512), [
                            [_vModelRadio, _ctx.formData.accountPlan]
                          ])
                        ])
                      ]),
                      _createElementVNode("label", _hoisted_42, [
                        _createElementVNode("span", _hoisted_43, [
                          _createElementVNode("span", _hoisted_44, [
                            _createElementVNode("span", _hoisted_45, [
                              _createElementVNode("span", _hoisted_46, [
                                _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra008.svg" })
                              ])
                            ])
                          ]),
                          _cache[25] || (_cache[25] = _createElementVNode("span", { class: "d-flex flex-column" }, [
                            _createElementVNode("span", { class: "fw-bolder text-gray-800 text-hover-primary fs-5" }, "Testing Account"),
                            _createElementVNode("span", { class: "fs-6 fw-bold text-gray-400" }, "Use images to enhance time travel rivers")
                          ], -1))
                        ]),
                        _createElementVNode("span", _hoisted_47, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            name: "accountPlan",
                            value: "3",
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.accountPlan) = $event))
                          }, null, 512), [
                            [_vModelRadio, _ctx.formData.accountPlan]
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "pb-10 pb-lg-12" }, [
                    _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Business Details"),
                    _createElementVNode("div", { class: "text-gray-400 fw-bold fs-6" }, [
                      _createTextVNode(" If you need more info, please check out "),
                      _createElementVNode("a", {
                        href: "#",
                        class: "link-primary fw-bolder"
                      }, "Help Page"),
                      _createTextVNode(". ")
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_50, [
                    _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label required" }, "Business Name", -1)),
                    _createVNode(_component_Field, {
                      name: "businessName",
                      class: "form-control form-control-lg form-control-solid",
                      value: "Keenthemes Inc."
                    }),
                    _createVNode(_component_ErrorMessage, {
                      class: "fv-plugins-message-container invalid-feedback",
                      name: "businessName"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _cache[29] || (_cache[29] = _createElementVNode("label", { class: "d-flex align-items-center form-label" }, [
                      _createElementVNode("span", { class: "required" }, "Shortened Descriptor"),
                      _createElementVNode("i", {
                        class: "fas fa-exclamation-circle ms-2 fs-7",
                        "data-bs-toggle": "popover",
                        "data-bs-trigger": "hover",
                        "data-bs-html": "true",
                        "data-bs-content": "\n                    <div class='p-4 rounded bg-light'>\n                        <div class='d-flex flex-stack text-muted mb-4'>\n                            <i class='fas fa-university fs-3 me-3'></i>\n\n                            <div class='fw-bold'>INCBANK **** 1245 STATEMENT</div>\n                        </div>\n\n                        <div class='d-flex flex-stack fw-bold text-gray-600'>\n                            <div>Amount</div>\n                            <div>Transaction</div>\n                        </div>\n\n                        <div class='separator separator-dashed my-2'></div>\n\n                        <div class='d-flex flex-stack text-dark fw-bolder mb-2'>\n                            <div>USD345.00</div>\n                            <div>KEENTHEMES*</div>\n                        </div>\n\n                        <div class='d-flex flex-stack text-muted mb-2'>\n                            <div>USD75.00</div>\n                            <div>Hosting fee</div>\n                        </div>\n\n                        <div class='d-flex flex-stack text-muted'>\n                            <div>USD3,950.00</div>\n                            <div>Payrol</div>\n                        </div>\n                    </div>\n                "
                      })
                    ], -1)),
                    _createVNode(_component_Field, {
                      name: "shortenedDescriptor",
                      class: "form-control form-control-lg form-control-solid",
                      value: "KEENTHEMES"
                    }),
                    _createVNode(_component_ErrorMessage, {
                      class: "fv-plugins-message-container invalid-feedback",
                      name: "shortenedDescriptor"
                    }),
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "form-text" }, " Customers will see this shortened version of your statement descriptor ", -1))
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _cache[32] || (_cache[32] = _createElementVNode("label", { class: "form-label required" }, "Corporation Type", -1)),
                    _createVNode(_component_Field, {
                      name: "corporationType",
                      class: "form-select form-select-lg form-select-solid",
                      as: "select"
                    }, {
                      default: _withCtx(() => _cache[31] || (_cache[31] = [
                        _createElementVNode("option", null, null, -1),
                        _createElementVNode("option", {
                          label: "Corporation",
                          value: "1"
                        }, "Corporation", -1),
                        _createElementVNode("option", {
                          label: "Sole Proprietorship",
                          value: "2"
                        }, " Sole Proprietorship ", -1),
                        _createElementVNode("option", {
                          label: "Non-profit",
                          value: "3"
                        }, "Non-profit", -1),
                        _createElementVNode("option", {
                          label: "Limited Liability",
                          value: "4"
                        }, " Limited Liability ", -1),
                        _createElementVNode("option", {
                          label: "General Partnership",
                          value: "5"
                        }, " General Partnership ", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ErrorMessage, {
                      class: "fv-plugins-message-container invalid-feedback",
                      name: "corporationType"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_53, [
                    _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label" }, "Business Description", -1)),
                    _createVNode(_component_Field, {
                      type: "text",
                      name: "businessDescription",
                      class: "form-control form-control-lg form-control-solid",
                      rows: "3"
                    }),
                    _createVNode(_component_ErrorMessage, {
                      class: "fv-plugins-message-container invalid-feedback",
                      name: "businessDescription"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _cache[34] || (_cache[34] = _createElementVNode("label", { class: "fs-6 fw-bold form-label required" }, "Contact Email", -1)),
                    _createVNode(_component_Field, {
                      name: "contactEmail",
                      class: "form-control form-control-lg form-control-solid",
                      value: "corp@support.com"
                    }),
                    _createVNode(_component_ErrorMessage, {
                      class: "fv-plugins-message-container invalid-feedback",
                      name: "contactEmail"
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _cache[43] || (_cache[43] = _createElementVNode("div", { class: "pb-10 pb-lg-15" }, [
                    _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Billing Details"),
                    _createElementVNode("div", { class: "text-gray-400 fw-bold fs-6" }, [
                      _createTextVNode(" If you need more info, please check out "),
                      _createElementVNode("a", {
                        href: "#",
                        class: "text-primary fw-bolder"
                      }, "Help Page"),
                      _createTextVNode(". ")
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_57, [
                    _cache[36] || (_cache[36] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                      _createElementVNode("span", { class: "required" }, "Name On Card"),
                      _createElementVNode("i", {
                        class: "fas fa-exclamation-circle ms-2 fs-7",
                        "data-bs-toggle": "tooltip",
                        title: "Specify a card holder's name"
                      })
                    ], -1)),
                    _createVNode(_component_Field, {
                      type: "text",
                      class: "form-control form-control-solid",
                      placeholder: "",
                      name: "nameOnCard"
                    }),
                    _createElementVNode("div", _hoisted_58, [
                      _createElementVNode("div", _hoisted_59, [
                        _createVNode(_component_ErrorMessage, { name: "nameOnCard" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_60, [
                    _cache[38] || (_cache[38] = _createElementVNode("label", { class: "required fs-6 fw-bold form-label mb-2" }, "Card Number", -1)),
                    _createElementVNode("div", _hoisted_61, [
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid",
                        placeholder: "Enter card number",
                        name: "cardNumber"
                      }),
                      _createElementVNode("div", _hoisted_62, [
                        _createElementVNode("div", _hoisted_63, [
                          _createVNode(_component_ErrorMessage, { name: "cardNumber" })
                        ])
                      ]),
                      _cache[37] || (_cache[37] = _createElementVNode("div", { class: "position-absolute translate-middle-y top-50 end-0 me-5" }, [
                        _createElementVNode("img", {
                          src: "media/svg/card-logos/visa.svg",
                          alt: "",
                          class: "h-25px"
                        }),
                        _createElementVNode("img", {
                          src: "media/svg/card-logos/mastercard.svg",
                          alt: "",
                          class: "h-25px"
                        }),
                        _createElementVNode("img", {
                          src: "media/svg/card-logos/american-express.svg",
                          alt: "",
                          class: "h-25px"
                        })
                      ], -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_64, [
                    _createElementVNode("div", _hoisted_65, [
                      _cache[41] || (_cache[41] = _createElementVNode("label", { class: "required fs-6 fw-bold form-label mb-2" }, "Expiration Date", -1)),
                      _createElementVNode("div", _hoisted_66, [
                        _createElementVNode("div", _hoisted_67, [
                          _createVNode(_component_Field, {
                            name: "expirationMonth",
                            class: "form-select form-select-solid",
                            "data-control": "select2",
                            "data-hide-search": "true",
                            "data-placeholder": "Month",
                            as: "select"
                          }, {
                            default: _withCtx(() => [
                              _cache[39] || (_cache[39] = _createElementVNode("option", null, null, -1)),
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
                                return _createElementVNode("option", {
                                  key: i,
                                  label: i,
                                  value: i
                                }, _toDisplayString(i), 9, _hoisted_68)
                              }), 64))
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_69, [
                            _createElementVNode("div", _hoisted_70, [
                              _createVNode(_component_ErrorMessage, { name: "expirationMonth" })
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_71, [
                          _createVNode(_component_Field, {
                            name: "expirationYear",
                            class: "form-select form-select-solid",
                            "data-control": "select2",
                            "data-hide-search": "true",
                            "data-placeholder": "Year",
                            as: "select"
                          }, {
                            default: _withCtx(() => [
                              _cache[40] || (_cache[40] = _createElementVNode("option", null, null, -1)),
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                                return _createElementVNode("option", {
                                  key: i,
                                  label: new Date().getFullYear() + i,
                                  value: new Date().getFullYear() + i
                                }, _toDisplayString(new Date().getFullYear() + i), 9, _hoisted_72)
                              }), 64))
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_73, [
                            _createElementVNode("div", _hoisted_74, [
                              _createVNode(_component_ErrorMessage, { name: "expirationYear" })
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_75, [
                      _cache[42] || (_cache[42] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
                        _createElementVNode("span", { class: "required" }, "CVV"),
                        _createElementVNode("i", {
                          class: "fas fa-exclamation-circle ms-2 fs-7",
                          "data-bs-toggle": "tooltip",
                          title: "Enter a card CVV code"
                        })
                      ], -1)),
                      _createElementVNode("div", _hoisted_76, [
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid",
                          minlength: "3",
                          maxlength: "4",
                          placeholder: "CVV",
                          name: "cvv"
                        }),
                        _createElementVNode("div", _hoisted_77, [
                          _createElementVNode("span", _hoisted_78, [
                            _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin002.svg" })
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("div", _hoisted_80, [
                          _createVNode(_component_ErrorMessage, { name: "cvv" })
                        ])
                      ])
                    ])
                  ]),
                  _cache[44] || (_cache[44] = _createStaticVNode("<div class=\"d-flex flex-stack\"><div class=\"me-5\"><label class=\"fs-6 fw-bold form-label\">Save Card for further billing?</label><div class=\"fs-7 fw-bold text-gray-400\"> If you need more info, please check budget planning </div></div><label class=\"form-check form-switch form-check-custom form-check-solid\"><input class=\"form-check-input\" type=\"checkbox\" value=\"1\" checked=\"checked\"><span class=\"form-check-label fw-bold text-gray-400\"> Save Card </span></label></div>", 1))
                ])
              ]),
              _createElementVNode("div", _hoisted_81, [
                _createElementVNode("div", _hoisted_82, [
                  _createElementVNode("div", _hoisted_83, [
                    _cache[48] || (_cache[48] = _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Your Are Done!", -1)),
                    _createElementVNode("div", _hoisted_84, [
                      _cache[46] || (_cache[46] = _createTextVNode(" If you need more info, please ")),
                      _createVNode(_component_router_link, {
                        to: "/sign-in",
                        class: "link-primary fw-bolder"
                      }, {
                        default: _withCtx(() => _cache[45] || (_cache[45] = [
                          _createTextVNode("Sign In")
                        ])),
                        _: 1
                      }),
                      _cache[47] || (_cache[47] = _createTextVNode(". "))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_85, [
                    _cache[50] || (_cache[50] = _createElementVNode("div", { class: "fs-6 text-gray-600 mb-5" }, " Writing headlines for blog posts is as much an art as it is a science and probably warrants its own post, but for all advise is with what works for your great & amazing audience. ", -1)),
                    _createElementVNode("div", _hoisted_86, [
                      _createElementVNode("span", _hoisted_87, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
                      ]),
                      _cache[49] || (_cache[49] = _createStaticVNode("<div class=\"d-flex flex-stack flex-grow-1\"><div class=\"fw-bold\"><h4 class=\"text-gray-800 fw-bolder\"> We need your attention! </h4><div class=\"fs-6 text-gray-600\"> To start using great tools, please, please <a href=\"#\" class=\"fw-bolder\">Create Team Platform</a></div></div></div>", 1))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_88, [
                _createElementVNode("div", _hoisted_89, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-lg btn-light-primary me-3",
                    "data-kt-stepper-action": "previous",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.previousStep()))
                  }, [
                    _createElementVNode("span", _hoisted_90, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr063.svg" })
                    ]),
                    _cache[51] || (_cache[51] = _createTextVNode(" Back "))
                  ])
                ]),
                _createElementVNode("div", null, [
                  (_ctx.currentStepIndex === _ctx.totalSteps - 1)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "submit",
                        class: "btn btn-lg btn-primary",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.formSubmit()))
                      }, [
                        _createElementVNode("span", _hoisted_91, [
                          _cache[52] || (_cache[52] = _createTextVNode(" Submit ")),
                          _createElementVNode("span", _hoisted_92, [
                            _createVNode(_component_inline_svg, { src: "icons/duotune/arrows/arr064.svg" })
                          ])
                        ]),
                        _cache[53] || (_cache[53] = _createElementVNode("span", { class: "indicator-progress" }, [
                          _createTextVNode(" Please wait... "),
                          _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                        ], -1))
                      ]))
                    : (_openBlock(), _createElementBlock("button", _hoisted_93, [
                        _cache[54] || (_cache[54] = _createTextVNode(" Continue ")),
                        _createElementVNode("span", _hoisted_94, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                        ])
                      ]))
                ])
              ])
            ], 32)
          ], 512)
        ])
      ])
    ])
  ], 512))
}