
import { computed, defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

interface Step1 {
  accountType: string;
}

interface Step2 {
  teamSize: string;
  teamAccountName: string;
  accountPlan: string;
}

interface Step3 {
  businessName: string;
  shortenedDescriptor: string;
  corporationType: string;
  businessDescription: string;
  contactEmail: string;
}

interface Step4 {
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-account-modal",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const createAccountModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<KTCreateApp>({
      accountType: "personal",
      teamSize: "1-1",
      teamAccountName: "",
      accountPlan: "1",
      businessName: "Keenthemes Inc.",
      shortenedDescriptor: "KEENTHEMES",
      corporationType: "1",
      businessDescription: "",
      contactEmail: "corp@support.com",
      nameOnCard: "Max Doe",
      cardNumber: "4111 1111 1111 1111",
      expirationMonth: "1",
      expirationYear: "2022",
      cvv: "123",
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
    });

    const createAppSchema = [
      Yup.object({}),
      Yup.object({
        teamAccountName: Yup.string().required().label("Team account name"),
      }),
      Yup.object({
        businessName: Yup.string().required().label("Business name"),
        shortenedDescriptor: Yup.string()
          .required()
          .label("Shortened descriptor"),
        corporationType: Yup.string().required().label("Corporation type"),
        businessDescription: Yup.string()
          .required()
          .label("Business description"),
        contactEmail: Yup.string().required().label("Contact email"),
      }),
      Yup.object({
        nameOnCard: Yup.string().required().label("Name"),
        cardNumber: Yup.string().required().label("Card number"),
        expirationMonth: Yup.string().required().label("Month"),
        expirationYear: Yup.string().required().label("Year"),
        cvv: Yup.string().required().label("CVV"),
      }),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        hideModal(createAccountModalRef.value);
      });
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData,
      createAccountModalRef,
    };
  },
});
